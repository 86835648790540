@import "variables";


/**************************************************
* Shopping cart tpl
*/
#print-quotation:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

#customer-information .form-group {
    float: left;
    width: 25%;
}

#customer-information .form-group label, #customer-information .clearfix label {
    padding-right: 10px;
}

#customer-information button {
    float: right;
}

#customer-information button i {
    font-size: 25px;
    line-height: 25px;
    vertical-align: -4px;
}


@media screen and (max-width: 768px) {
    /**************************************************
    * Shopping cart tpl
    */
    #customer-information .form-group {
        float: left;
        width: 50%;
    }
    
    #customer-information .form-group label {
        display: block;
    }
}

